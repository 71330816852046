import 'liquid-ajax-cart'

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
// import AlpineFocus from '@alpinejs/focus'
// import AlpineMorph from '@alpinejs/morph'
import intersect from '@alpinejs/intersect'
import AlpineGlobals from '../js/alpine/index.js'
import helpers, { hasBodyClass } from '../js/helpers.js'
import criticalHomeStyles from '../css/criticalHomeStyles.css?inline'
import criticalProductStyles from '../css/criticalProductStyles.css?inline'

import Swiper from 'swiper'
import { Autoplay, EffectFade, Navigation, Pagination, Zoom } from 'swiper/modules'

Swiper.use([Zoom, Pagination, Navigation, Autoplay, EffectFade ])

import 'swiper/css'
import 'swiper/css/zoom'
import 'swiper/css/effect-fade'

import AOS from 'aos'
// Было
// import 'aos/dist/aos.css'

// Стало
// AOS styles (можно грузить только при наличии анимаций)
if (document.querySelector('[data-aos]')) {
  import('aos/dist/aos.css');
}

// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// Dynamic imports
hasBodyClass('product-template') && import('../js/prodify.js')

// function loadCriticalStyles() {
//   let criticalStyles
//
//   if (document.body.classList.contains('index-template')) {
//     criticalStyles = criticalHomeStyles
//   } else if (document.body.classList.contains('product-template')) {
//     criticalStyles = criticalProductStyles
//   } else if (document.body.classList.contains('template-collection')) {
//     // criticalStyles = criticalCategoryStyles
//   } else {
//     // Загрузка базовых критических стилей для других страниц
//     criticalStyles = ''
//   }
//
//   document.head.insertAdjacentHTML('beforeend', `<style>${criticalStyles}</style>`)
// }
//
// document.addEventListener('DOMContentLoaded', loadCriticalStyles)

const ns = 'slayed'

window.Swiper = Swiper
window.slayedNamespace = ns
window[ns] = (window[ns] || {})
window[ns].helpers = helpers

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value
}

// Register and initialize AlpineJS
window.Alpine = Alpine

Alpine.plugin(
  [
    AlpineCollapse,
    // AlpineFocus,
    // AlpineMorph,
    intersect
  ]
)
AlpineGlobals.register(Alpine)
Alpine.start()

// Initialize Swiper
document.addEventListener('DOMContentLoaded', () => {

 /* function initCpHeroSlider() {
    // console.log('Initializing Hero Slider')
    new Swiper('#cpHeroSlider', {
      slidesPerView: 1,
      loop: false,
      centeredSlides: true,
      animateTransitions: true,
      speed: 1000,
      // preloadImages: false,
      lazy: true,
      preloadImages: 'all',
      autoplay: {
        delay: 3500,
        disableOnInteraction: false
      },
      pagination: {
        el: '.hero-slider .swiper-pagination-vertical',
        clickable: true
      }
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    })

  }

  function initCpCultuurSlider() {
    // console.log('Initializing Culturuur Slider')
    new Swiper('#cpCultuutSlider', {
      slidesPerView: 'auto',
      spaceBetween: 8,
      loop: false,
      centeredSlides: false,
      animateTransitions: true,
      // speed: 1000,
      // preloadImages: false,
      lazy: true,
      preloadImages: 'all',
      // autoplay: {
      //   delay: 3500,
      //   disableOnInteraction: false
      // },
      // pagination: {
      //   el: '.hero-slider .swiper-pagination-vertical',
      //   clickable: true
      // }
      navigation: {
        nextEl: '.about_cultuur .swiper-button-next',
        prevEl: '.about_cultuur .swiper-button-prev',
      },
    })
  }

  function initPromoBarSlider() {
    // console.log('Initializing Promo Bar Slider')
    new Swiper('.announcement_swiper', {
      direction: 'vertical',
      // autoHeight: true,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1500,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      }
    })
  }

  function initCpAboutSlider() {
    // console.log('Initializing About Slider')
    new Swiper('#cpAboutSlider', {
      slidesPerView: 1,
      loop: true,
      centeredSlides: false,
      speed: 500,
      effect: 'fade',
      // preloadImages: false,
      // lazy: true,
      // preloadImages: 'all',
      pagination: {
        el: '#cpAboutSlider .swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '#cpAboutSlider .swiper-button-next',
        prevEl: '#cpAboutSlider .swiper-button-prev'
      }
    })
  }

  function initCpProductsSlider() {
    // Select all elements with the slider class
    const sliders = document.querySelectorAll('.products-slider__swiper');

    // Loop through each slider element and initialize Swiper
    sliders.forEach(slider => {
      new Swiper(slider, {
        slidesPerView: 'auto',
        spaceBetween: 8,
        loop: false,
        centeredSlides: true,
        animateTransitions: true,
        lazy: true,
        watchSlidesProgress: true,
        preloadImages: false,
        speed: 500,
        navigation: {
          nextEl: slider.closest('.products_slider').querySelector('.swiper-button-next'),
          prevEl: slider.closest('.products_slider').querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            centeredSlides: false
          }
        }
      });
    });
  }

  function initCpReviewsSlider() {
    // console.log('Initializing Reviews mob Slider')
    new Swiper('#cpReviewsSlider', {
      slidesPerView: 1,
      spaceBetween: 8,
      loop: false,
      centeredSlides: false,
      lazy: true,
      watchSlidesProgress: true,
      preloadImages: false,
      // animateTransitions: true,
      // speed: 500,
      // freeMode: true,
      // autoplay: {
      //   delay: 3500,
      //   disableOnInteraction: false
      // },
      pagination: {
        el: '#cpReviewsSlider .swiper-pagination',
        clickable: true
      },
      // navigation: {
      //   nextEl: '.products_slider .swiper-button-next',
      //   prevEl: '.products_slider .swiper-button-prev',
      // },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        821: {
          slidesPerView: 1
        }
      }
    })
  }
*/

  function initializeSlider(selector, options) {
    const element = typeof selector === 'string' ? document.querySelector(selector) : selector;
    if (!element) {
      console.warn(`Элемент с селектором "${selector}" не найден.`);
      return null;
    }

    try {
      const swiperInstance = new Swiper(element, options);
      console.log(`Слайдер инициализирован для селектора "${selector}"`);
      return swiperInstance;
    } catch (error) {
      console.error(`Ошибка инициализации Swiper для "${selector}":`, error);
      return null;
    }
  }

  function initializeResponsiveSlider(selector, options, breakpoint = 768) {
    const handleSlider = () => {
      const element = document.querySelector(selector);
      if (!element) return;

      if (window.innerWidth < breakpoint && !handleSlider.swiper) {
        handleSlider.swiper = new Swiper(element, options);
        console.log(`Слайдер инициализирован для селектора "${selector}"`);
      } else if (window.innerWidth >= breakpoint && handleSlider.swiper) {
        handleSlider.swiper.destroy(true, true);
        handleSlider.swiper = null;
        console.log(`Слайдер уничтожен для селектора "${selector}"`);
      }
    };

    handleSlider();
    window.addEventListener('resize', handleSlider);
  }

// Инициализация слайдеров с помощью новой функции

  initializeSlider('#cpHeroSlider', {
    slidesPerView: 1,
    loop: false,
    centeredSlides: true,
    speed: 1000,
    lazy: true,
    autoplay: { delay: 3500, disableOnInteraction: false },
    pagination: { el: '.hero-slider .swiper-pagination-vertical', clickable: true }
  });

  initializeSlider('#cpCultuutSlider', {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: false,
    centeredSlides: false,
    lazy: true,
    navigation: { nextEl: '.about_cultuur .swiper-button-next', prevEl: '.about_cultuur .swiper-button-prev' }
  });

  initializeSlider('.announcement_swiper', {
    direction: 'vertical',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1500,
    autoplay: { delay: 2500, disableOnInteraction: false }
  });

  initializeSlider('#cpAboutSlider', {
    slidesPerView: 1,
    loop: true,
    centeredSlides: false,
    speed: 500,
    effect: 'fade',
    pagination: { el: '#cpAboutSlider .swiper-pagination', clickable: true },
    navigation: { nextEl: '#cpAboutSlider .swiper-button-next', prevEl: '#cpAboutSlider .swiper-button-prev' }
  });

  document.querySelectorAll('.products-slider__swiper').forEach(slider => {
    initializeSlider(slider, {
      slidesPerView: 'auto',
      spaceBetween: 8,
      loop: false,
      centeredSlides: true,
      lazy: true,
      watchSlidesProgress: true,
      speed: 500,
      navigation: {
        nextEl: slider.closest('.products_slider').querySelector('.swiper-button-next'),
        prevEl: slider.closest('.products_slider').querySelector('.swiper-button-prev')
      },
      breakpoints: { 768: { slidesPerView: 'auto', centeredSlides: false } }
    });
  });

  initializeSlider('#cpReviewsSlider', {
    slidesPerView: 1,
    spaceBetween: 8,
    loop: false,
    centeredSlides: false,
    lazy: true,
    watchSlidesProgress: true,
    pagination: { el: '#cpReviewsSlider .swiper-pagination', clickable: true },
    breakpoints: { 768: { slidesPerView: 2 }, 821: { slidesPerView: 1 } }
  });

  initializeResponsiveSlider('#cpProductSlider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: false,
    pagination: { el: '#cpProductSlider .swiper-pagination', clickable: true }
  });

  initializeResponsiveSlider('#PagecpProductSlider', {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: false,
    pagination: { el: '#PagecpProductSlider .swiper-pagination', clickable: true }
  });

  initializeResponsiveSlider('#cpUpsellSlider', {
    slidesPerView: 'auto',
    spaceBetween: 1,
    loop: false,
    centeredSlides: false,
    pagination: {
      el: '#cpUpsellSlider .swiper-pagination',
      clickable: true,
      renderBullet: function(index, className) {
        const slides = this.slides;
        return slides[index].classList.contains('active') ? `<span class="${className}"></span>` : '';
      }
    }
  });

  initializeResponsiveSlider('#vacaturesSlider', {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: true,
    centeredSlides: false
  });

  window.validateEmail = function() {
    helpers.emailValidator().validateEmail()
  }

  // function initCpProductSlider() {
  //   // Function to initialize or destroy the slider based on screen width
  //   window.handleSlider = function() {
  //     if (helpers.getWindowWidth() < 768) {
  //       console.debug('Product Slider Initialized???????')
  //       // console.log('Initializing Single Product Slider for Mobile');
  //       window.handleSlider.swiper = new Swiper('#cpProductSlider', {
  //         slidesPerView: 1,
  //         spaceBetween: 0,
  //         loop: true,
  //         centeredSlides: false,
  //         pagination: {
  //           el: '#cpProductSlider .swiper-pagination',
  //           clickable: true
  //         }
  //       })
  //
  //     } else if (helpers.getWindowWidth() > 768 && window.handleSlider.swiper) {
  //       // console.log('Destroying Single Product Slider because it is not a mobile device.');
  //       window.handleSlider.swiper.destroy(true, true)
  //       window.handleSlider.swiper = null
  //     }
  //   }
  //
  //   // Call handleSlider initially
  //   window.handleSlider()
  //
  //   // Attach event listener to window resize event
  //   window.addEventListener('resize', window.handleSlider)
  // }


  // function initPageCpProductSlider() {
  //   window.handleSlider = function() {
  //       console.debug('Product Slider Initialized???????')
  //       // console.log('Initializing Single Product Slider for Mobile');
  //       window.handleSlider.swiper = new Swiper('#PagecpProductSlider', {
  //         slidesPerView: 1,
  //         spaceBetween: 0,
  //         loop: true,
  //         centeredSlides: false,
  //         pagination: {
  //           el: '#PagecpProductSlider .swiper-pagination',
  //           clickable: true
  //         }
  //       })
  //
  //   }
  //
  //   // Call handleSlider initially
  //   window.handleSlider()
  //
  //   /*window.handleSlider.swiper = new Swiper('#PagecpProductSlider', {
  //     slidesPerView: 1,
  //     spaceBetween: 0,
  //     loop: true,
  //     centeredSlides: false,
  //     pagination: {
  //       el: '#PagecpProductSlider .swiper-pagination',
  //       clickable: true
  //     }
  //   })*/
  // }


// Initialize the product slider
//   initCpProductSlider();


  // function initCpUpsellCartSlider() {
  //   // Function to initialize or destroy the slider based on screen width
  //   window.handleCartUpsellSlider = function() {
  //     if (helpers.getWindowWidth() < 768 && !window.handleCartUpsellSlider.swiper) {
  //       // console.log('Initializing initCpUpsellCartSlider Slider for Mobile');
  //       window.handleCartUpsellSlider.swiper = new Swiper('#cpUpsellSlider', {
  //         slidesPerView: 'auto',
  //         spaceBetween: 1,
  //         loop: false,
  //         centeredSlides: false,
  //         pagination: {
  //           el: '#cpUpsellSlider .swiper-pagination',
  //           clickable: true,
  //           renderBullet: function(index, className) {
  //             // Check if the corresponding slide has the 'special' class
  //             var slides = this.slides
  //             if (slides[index].classList.contains('active')) {
  //               return '<span class="' + className + '"></span>'
  //             } else {
  //               return '' // Return an empty string for slides without the 'special' class
  //             }
  //           }
  //         }
  //       })
  //     } else if (helpers.getWindowWidth() > 768 && window.handleCartUpsellSlider.swiper) {
  //       // console.log('Destroying Single Product Slider because it is not a mobile device.');
  //       window.handleCartUpsellSlider.swiper.destroy(true, true)
  //       window.handleCartUpsellSlider.swiper = null
  //     }
  //   }
  //
  //   // Call handleSlider initially
  //   window.handleCartUpsellSlider()
  //
  //   // Attach event listener to window resize event
  //   window.addEventListener('resize', window.handleCartUpsellSlider)
  // }

  // function initCpVacaturesSlider() {
  //   // Function to initialize or destroy the slider based on screen width
  //   window.handleVacaturesSlider = function() {
  //     if (helpers.getWindowWidth() < 768 && !window.handleVacaturesSlider.swiper) {
  //       // console.log('Initializing handleVacaturesSlider Slider for Mobile');
  //       window.handleVacaturesSlider.swiper = new Swiper('#vacaturesSlider', {
  //         slidesPerView: 'auto',
  //         spaceBetween: 8,
  //         loop: true,
  //         centeredSlides: false
  //       })
  //     } else if (helpers.getWindowWidth() > 768 && window.handleVacaturesSlider.swiper) {
  //       // console.log('Destroying Vacatures Slider because it is not a mobile device.');
  //       window.handleVacaturesSlider.swiper.destroy(true, true)
  //       window.handleVacaturesSlider.swiper = null
  //     }
  //   }
  //
  //   // Call handleSlider initially
  //   window.handleVacaturesSlider()
  //
  //   // Attach event listener to window resize event
  //   window.addEventListener('resize', window.handleVacaturesSlider)
  // }

// Initialize the product slider
//   initCpUpsellCartSlider()

  // initCpVacaturesSlider();


  // function initUpsellsSlider() {
  //
  //   let swiper = null // Variable to hold the swiper instance
  //
  //   // Function to initialize or destroy the slider based on screen width
  //   function handleSlider() {
  //     if (helpers.getWindowWidth() < 1024 && swiper === null) {
  //       // console.log('Initializing Upsell cart Slider for Mobile')
  //       swiper = new Swiper('#cpUpsellsSlider', {
  //         slidesPerView: 'auto',
  //         spaceBetween: 8,
  //         loop: false,
  //         centeredSlides: true,
  //         animateTransitions: true,
  //         speed: 500,
  //         navigation: {
  //           nextEl: '.product_upsells .swiper-button-next',
  //           prevEl: '.product_upsells .swiper-button-prev'
  //         },
  //         breakpoints: {
  //           768: {
  //             slidesPerView: 2,
  //             centeredSlides: false
  //           }
  //         }
  //       })
  //     } else if (helpers.getWindowWidth() > 1024 && swiper !== null) {
  //       // console.log('Destroying Single Product Slider because it is not a mobile device.')
  //       swiper.destroy(true, true)
  //       swiper = null
  //     }
  //   }
  //
  //   // Call handleSlider initially
  //   handleSlider()
  //
  //   // Attach event listener to window resize event
  //   window.addEventListener('resize', handleSlider)
  // }


  if (document.getElementById('site-promo-bar')) {
    // initPromoBarSlider()
  }

  if (document.getElementById('cpCultuutSlider')) {
    // initCpCultuurSlider()
  }

  if (document.getElementById('cpReviewsSlider')) {
    // initCpReviewsSlider()
  }

  if (document.getElementById('cpHeroSlider')) {
    // initCpHeroSlider()
  }

  if (document.getElementById('cpAboutSlider')) {
    // initCpAboutSlider()
  }

  if (document.getElementById('cpProducts')) {
    // initCpProductsSlider()
  }

  if (document.getElementById('cpProductSlider')) {
    // initCpProductSlider()
  }

  if (document.getElementById('PagecpProductSlider')) {
    // initPageCpProductSlider()
  }

  // if (document.getElementById('cpUpsellsSlider')) {
    // initUpsellsSlider()
  // }

  function initializeAccordion(selector) {
    const accordionItems = document.querySelectorAll(selector);

    accordionItems.forEach((item) => {
      const header = item.querySelector('header');
      const description = item.querySelector('.ar_description');

      if (header && description) { // Проверяем, что header и description существуют
        header.addEventListener('click', () => {
          const isOpen = item.classList.toggle('open');
          description.style.height = isOpen ? `${description.scrollHeight}px` : '0px';
          closeOtherAccordions(item);
        });
      }
    });

    function closeOtherAccordions(currentItem) {
      accordionItems.forEach((item) => {
        const description = item.querySelector('.ar_description');
        if (item !== currentItem && item.classList.contains('open') && description) {
          item.classList.remove('open');
          description.style.height = '0px';
        }
      });
    }
  }

  initializeAccordion('.ar_accordion-content');

  function ReviewsScroll(elementClass, direction) {
    const column = document.querySelector(elementClass)
    const section = document.querySelector('.reviews')

    function handleScroll() {
      if (helpers.getWindowWidth() > 1024) {
        const rect = column.getBoundingClientRect()
        const columnTop = column.offsetTop
        const scrollPosition = window.scrollY - columnTop
        // console.log(columnTop)
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          column.style.transform = `translateY(${direction === 'down' ? -(scrollPosition - 4000) * 0.2 : scrollPosition * 0.2}px)`
        } else {
          column.style.transform = `translateY(${direction === 'up' ? -(scrollPosition - 4000) * 0.2 : scrollPosition * 0.2}px)`
        }
      } else {
        column.style.transform = 'none'
      }
    }

    // Call handleScroll function to check if the .reviews section is in the viewport when the page loads
    if (document.getElementById('cpReviewsSlider')) {
      handleScroll()
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', handleScroll)
        } else {
          window.removeEventListener('scroll', handleScroll)
        }
      })
    })
    if (document.getElementById('cpReviewsSlider')) {
      observer.observe(section)
    }
  }

  ReviewsScroll('.scroll_top', 'up')
  ReviewsScroll('.scroll_bottom', 'down')

  function ReviewsScroll3(elementClass, direction) {
    const column = document.querySelector(elementClass)
    const section = document.querySelector('.reviews')

    function handleScroll() {
        const rect = column.getBoundingClientRect()
        const columnTop = column.offsetTop
        const scrollPosition = window.scrollY - columnTop
        // console.log(columnTop)
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          column.style.transform = `translateY(${direction === 'down' ? -(scrollPosition - 4000) * 0.2 : scrollPosition * 0.2}px)`
        } else {
          column.style.transform = `translateY(${direction === 'up' ? -(scrollPosition - 4000) * 0.2 : scrollPosition * 0.2}px)`
        }
    }

    // Call handleScroll function to check if the .reviews section is in the viewport when the page loads
    if (document.getElementById('bnr')) {
      handleScroll()
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', handleScroll)
        } else {
          window.removeEventListener('scroll', handleScroll)
        }
      })
    })
    if (document.getElementById('bnr')) {
      observer.observe(section)
    }
  }

  ReviewsScroll3('.s_scroll_top', 'up')
  ReviewsScroll3('.s_scroll_bottom', 'down')


  function ReviewsScroll2(elementClass, direction) {
    const column = document.querySelector(elementClass)
    const section = document.querySelector('.extra')

    function handleScroll() {
      if (helpers.getWindowWidth() > 1024) {
        const rect = column.getBoundingClientRect()
        const columnTop = column.offsetTop
        const scrollPosition = window.scrollY - columnTop

        let translateValue = scrollPosition * 0.2
        if (direction === 'left') {
          translateValue = scrollPosition * 0.2
        } else if (direction === 'right') {
          translateValue = -scrollPosition * 0.2
        }

        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          column.style.transform = `translateX(${translateValue}px)`
        } else {
          column.style.transform = `translateX(${translateValue}px)`
        }
      } else {
        column.style.transform = 'none'
      }
    }

    let scheduledAnimationFrame = false

    function onScroll() {
      if (!scheduledAnimationFrame) {
        scheduledAnimationFrame = true
        requestAnimationFrame(() => {
          handleScroll()
          scheduledAnimationFrame = false
        })
      }
    }

    if (document.getElementById('horizontalSlider')) {
      handleScroll()
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', onScroll)
        } else {
          window.removeEventListener('scroll', onScroll)
        }
      })
    })

    if (document.getElementById('horizontalSlider')) {
      observer.observe(section)
    }
  }

  ReviewsScroll2('.scroll_left', 'left')
  ReviewsScroll2('.scroll_right', 'right')


  function addScrollerAnimation() {
    const scrollers = document.querySelectorAll('.scroller')
    const scrollersx = document.querySelectorAll('.scrollerx')
    scrollers.forEach((scroller) => {
      // add data-animated="true" to every `.scroller` on the page
      scroller.setAttribute('data-animated', true)

      // Make an array from the elements within `.scroller-inner`
      const scrollerInner = scroller.querySelector('.scroller__inner')
      const scrollerContent = Array.from(scrollerInner.children)

      // For each item in the array, clone it
      // add aria-hidden to it
      // add it into the `.scroller-inner`
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true)
        duplicatedItem.setAttribute('aria-hidden', true)
        scrollerInner.appendChild(duplicatedItem)
      })
    })

    scrollersx.forEach((scroller) => {
      // add data-animated="true" to every `.scroller` on the page
      scroller.setAttribute('data-animated', true)

      // Make an array from the elements within `.scroller-inner`
      const scrollerInner = scroller.querySelector('.scroller__inner')
      const scrollerContent = Array.from(scrollerInner.children)

      // For each item in the array, clone it
      // add aria-hidden to it
      // add it into the `.scroller-inner`
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true)
        duplicatedItem.setAttribute('aria-hidden', true)
        scrollerInner.appendChild(duplicatedItem)
      })
    })
  }

  if (document.getElementById('cpUpsellSlider')) {
    addScrollerAnimation()
  }


  function showHeaderOnScrollTop() {
    const header = document.getElementById('shopify-section-header');
    let lastScrollY = window.scrollY;

    if (!header) return; // Проверка на наличие заголовка

    const handleScroll = () => {
      if (window.scrollY < lastScrollY) {
        header.classList.add('!top-0');
      } else {
        header.classList.remove('!top-0');
      }
      lastScrollY = window.scrollY;
    };

    let scheduledAnimationFrame = false;

    window.addEventListener('scroll', () => {
      if (!scheduledAnimationFrame) {
        scheduledAnimationFrame = true;
        requestAnimationFrame(() => {
          handleScroll();
          scheduledAnimationFrame = false;
        });
      }
    });
  }

  showHeaderOnScrollTop();


// Call the function initially
//   if (helpers.getWindowWidth() > 960) {
//     ReviewsScroll(".scroll_top", "up");
//     ReviewsScroll(".scroll_bottom", "down");
//   } else {
  // Reset the transform style when window width is less than 960px
  // document.querySelector(".scroll_top").style.transform = 'none';
  // document.querySelector(".scroll_bottom").style.transform = 'none';
  // }
  /*const stickyAdc = document.getElementById('sticky_adc')
  const stickyScrollFunc = function() {
    const y = window.scrollY
    // if (window.innerWidth < 768) {
      // if (y >= 0) {
      //   stickyAdc.classList.remove('translate-y-full')
      // } else {
      //   stickyAdc.classList.add('translate-y-full')
      // }
    // } else {
      // stickyAdc.classList.add('sticky-adc__show')

      const options = {
        root: null,
        rootMargin: '-80px',
        threshold: 0.5 // 50% of the element is visible
      }

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Your code here for when the element is in the viewport
            stickyAdc.classList.add('lg:translate-y-full')
          } else {
            stickyAdc.classList.remove('lg:translate-y-full')
          }
        })
      }, options)

      const targetElement = document.getElementById('form_wrapper')

      observer.observe(targetElement)

    // }
  }

  if (stickyAdc) {
    window.addEventListener('scroll', stickyScrollFunc)
  }*/
})


// Hide the Shopify preview bar when in development
// if (process.env.NODE_ENV === 'development') {
//   //
//   window.addEventListener('DOMContentLoaded', () => {
//     var css = '#preview-bar-iframe { display: none !important; }',
//       head = document.head || document.getElementsByTagName('head')[0],
//       style = document.createElement('style')

//     head.appendChild(style)

//     style.appendChild(document.createTextNode(css))
//   })
// }

window.AOS = AOS
function initializeAOS() {
  if (document.querySelector('[data-aos]')) {
    import('aos').then(AOS => {
      AOS.init({
        disable: false,
        startEvent: 'DOMContentLoaded',
        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        useClassNames: false,
        disableMutationObserver: false,
        debounceDelay: 50,
        throttleDelay: 99,
        offset: 120,
        delay: 0,
        duration: 500,
        easing: 'ease',
        once: false,
        mirror: false,
        anchorPlacement: 'top-bottom'
      });
      console.log("AOS инициализирован");
    }).catch(error => console.error("Ошибка загрузки AOS:", error));
  } else {
    console.log("AOS не требуется, элементы с data-aos отсутствуют");
  }
}

initializeAOS();
