export const hasBodyClass = (className) => {
  return document.body.classList.contains(className)
}

export default {
  /**
   * Emit a custom event
   * @param  {String} type   The event type
   * @param  {Object} detail Any details to pass along with the event
   * @param  {Node}   elem   The element to attach the event to
   */
  emitEvent(type, detail = {}, elem = document) {
    if (!type) return

    let event = new CustomEvent(type, {
      bubbles: true,
      cancelable: true,
      detail: detail
    })

    return elem.dispatchEvent(event)
  },

  randomNumber(min = 0, max = 1000) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },

  getWindowWidth() {
    return window.innerWidth
  },

  runFunctionAtWidth(fn, width) {
    window.addEventListener('resize', () => {
      if (window.innerWidth === width) {
        fn()
      }
    })

    // Call the function initially if the current window width matches the specified width
    if (window.innerWidth === width) {
      fn()
    }
  },

  debounce(fn, wait) {
    let t
    return (...args) => {
      clearTimeout(t)
      t = setTimeout(() => fn.apply(this, args), wait)
    }
  },

  truncateLongTitle(input) {
    return input.length > 5 ? `${input.substring(0, 18)}...` : input
  },

  async fetchHTML(endpoint) {
    return await fetch(endpoint)
      .then((response) => response.text())
      .then((responseText) => {
        return new DOMParser().parseFromString(responseText, 'text/html')
      })
  },

  pluralize(word, language) {
    // English rules
    if (language === 'english') {
      if (word.endsWith('y')) {
        return word.slice(0, -1) + 'ies'
      } else if (word.endsWith('s') || word.endsWith('x') || word.endsWith('z') || word.endsWith('ch') || word.endsWith('sh')) {
        return word + 'es'
      } else {
        return word + 's'
      }
    }

    // Dutch rules
    if (language === 'dutch') {
      if (word.endsWith('s') || word.endsWith('x') || word.endsWith('z') || word.endsWith('ch') || word.endsWith('sh')) {
        return word + 'en'
      } else {
        return word + 's'
      }
    }

    // If the language is not supported, return the word as is
    return word
  },

  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },

  emailValidator() {
    return {
      email: '',
      isValid: false,
      loading: false,
      validateEmail() {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        this.isValid = regex.test(this.email);
      },
    }
  }
}

export const emailValidator = () => {
  return {
    email: '',
    isValid: false,
    loading: false,
    validateEmail() {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      this.isValid = regex.test(this.email);
    },
  }
}
